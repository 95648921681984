import React, {FC, useState, FormEvent} from 'react';
import { useQuery } from '@apollo/client';
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress, Grid,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material';
import { COMPANION_OPTIONS_QUERY, SPOT_POLICY_SUBCATEGORIES_QUERY } from './BillForm.graphql';
import {
  CompanionOptionsQueryInterface, CompanionOptionsQueryVariableInterface,
  SpotPolicySubcategoriesQueryInterface,
  SpotPolicySubcategoriesQueryVariableInterface,
} from './BillForm.interface';
import { spotClient } from '../../../common/apolloSpotClient';
import {policyText} from "../../../const/policyText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {statusText} from "../../../const/statusText";
import {StatusEnum} from "../../../types/Status.enum";
import {subcategoryText} from "../../../const/subcategoryText";
import {PolicyEnum} from "../../../types/Policy.enum";
import {SubcategoryEnum} from "../../../types/Subcategory.enum";
import {stateText} from "../../../const/stateText";
import {chamberText} from "../../../const/chamberText";
import {StateEnum} from "../../../types/State.enum";
import {ChamberEnum} from "../../../types/Chamber.enum";
import {BillInterface} from "../../../types/Bill.interface";
import Box from "@mui/material/Box";
import {ThemeProvider} from "@mui/material/styles";
import buttonTheme from "../../../theme/buttonTheme";

export interface FormBillInterface {
  id: string
  title: string
  summary: string
  yearFiled: number
  externalId: number
  state: StateEnum
  number: string
  chamber: ChamberEnum
  session: string
  sessionId: number
  status: StatusEnum
  policyCategory?: PolicyEnum
  policySubcategory: SubcategoryEnum[]
  spotPolicies: number[]
  companionBills: string[]
}

interface BillFormProps {
  formTitle: string
  bill: FormBillInterface
  onCancel: () => void
  onSubmit: (data: FormBillInterface) => void
}

const getBillLabel = (bill: BillInterface) => `${bill.state} ${bill.number} - ${bill.yearFiled}: ${bill.title}`;

export const BillForm: FC<BillFormProps> = ({ formTitle, bill, onCancel, onSubmit }) => {
  const [companionOptions, setCompanionOptions] = useState<BillInterface[]>([]);

  const [formState, setFormState] = useState<FormBillInterface>({
    id: bill.id,
    title: bill.title,
    summary: bill.summary,
    yearFiled: bill.yearFiled,
    externalId: bill.externalId,
    state: bill.state,
    number: bill.number,
    chamber: bill.chamber,
    session: bill.session,
    sessionId: bill.sessionId,
    status: bill.status,
    policyCategory: bill.policyCategory,
    policySubcategory: bill.policySubcategory,
    spotPolicies: bill.spotPolicies,
    companionBills: bill.companionBills,
  });

  useQuery<CompanionOptionsQueryInterface, CompanionOptionsQueryVariableInterface>(COMPANION_OPTIONS_QUERY, {
    // Companion bills are handled by a separate service/endpoint/query/entity.
    // None of the bill queries pull in companion data, the companion cache is
    // never updated. So BillForm has to pull companions raw from the network.
    fetchPolicy: 'network-only',
    variables: {
      companionsOf: bill.id,
      companionOptions: {
        inSessionIds: [bill.sessionId],
        pageSize: 1000
      },
    },
    onCompleted(data) {
      setFormState({...formState, companionBills: data.companionBills.map(v => v.billId)})
      setCompanionOptions(data.companionOptions.filter((b) => b.billId !== bill.id));
    },
  });

  const {
    data: spotPolicySubcategoryData,
    loading: spotPolicySubcategoryLoading,
    error: spotPolicySubcategoryError,
  } = useQuery<SpotPolicySubcategoriesQueryInterface, SpotPolicySubcategoriesQueryVariableInterface>(
    SPOT_POLICY_SUBCATEGORIES_QUERY,
    {
      client: spotClient,
      variables: {
        active: true,
      },
    },
  );

  const handlePolicyCategoryChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value === 'NONE' ? undefined : value });
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSubmit({
      id: bill.id,
      externalId: formState.externalId,
      policyCategory: formState.policyCategory,
      policySubcategory: formState.policySubcategory,
      spotPolicies: formState.spotPolicies,
      companionBills: formState.companionBills,
      title: formState.title,
      summary: formState.summary,
      state: formState.state,
      number: formState.number,
      session: formState.session,
      sessionId: bill.sessionId,
      chamber: formState.chamber,
      status: formState.status,
      yearFiled: formState.yearFiled
    });
  };

  const currentYear = new Date().getFullYear();
  const yearsStart = 2010;
  const yearOptions = Array.from(Array(currentYear - yearsStart + 1).keys())
    .map((v) => v + yearsStart)
    .reverse()

  return (
    <>
      <h1>{formTitle}</h1>
      <form onSubmit={handleSubmit}>
          <h2>Selected Bill</h2>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="bill-form-title"
                label="Title"
                variant="outlined"
                value={formState.title}
                onChange={(e) => setFormState({ ...formState, title: e.target.value })} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="bill-form-summary"
                label="Summary"
                variant="outlined"
                multiline
                rows={4}
                value={formState.summary}
                onChange={(e) => setFormState({ ...formState, summary: e.target.value })} />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <FormControl fullWidth>
                <InputLabel
                  id="bill-form-year-filed-label"
                  htmlFor="bill-form-year-filed">Year filed</InputLabel>
                <Select
                  labelId="bill-form-year-filed-label"
                  id="bill-form-year-filed"
                  value={formState.yearFiled}
                  label="Year filed"
                  onChange={(e) => setFormState({...formState, yearFiled: Number.parseInt(`${e.target.value}`, 10)})}
                >
                  {yearOptions.map((year) => (
                    <MenuItem key={year} value={year}>{year}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <TextField
                fullWidth
                id="bill-form-external-id"
                label="LegiScan ID"
                variant="outlined"
                value={formState.externalId}
                onChange={(e) => setFormState({ ...formState, externalId: Number.parseInt(e.target.value, 10) })} />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <FormControl fullWidth>
                <InputLabel
                  id="bill-form-state-label"
                  htmlFor="bill-form-state">State</InputLabel>
                <Select
                  labelId="bill-form-state-label"
                  id="bill-form-state"
                  value={formState.state}
                  label="State"
                  onChange={handlePolicyCategoryChange}
                >
                  {Object.entries(stateText).map(([value, label]) => (
                    <MenuItem key={value} value={value}>{label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <TextField
                fullWidth
                id="bill-form-number"
                label="Number"
                variant="outlined"
                value={formState.number}
                onChange={(e) => setFormState({ ...formState, number: e.target.value })} />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <FormControl fullWidth>
                <InputLabel
                  id="bill-form-state-label"
                  htmlFor="bill-form-chamber">Chamber</InputLabel>
                <Select
                  labelId="bill-form-state-label"
                  id="bill-form-chamber"
                  value={formState.chamber}
                  label="Chamber"
                  onChange={handlePolicyCategoryChange}
                >
                  {Object.entries(chamberText).map(([value, label]) => (
                    <MenuItem key={value} value={value}>{label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <TextField
                fullWidth
                id="bill-form-session"
                label="Session"
                variant="outlined"
                value={formState.session}
                onChange={(e) => setFormState({ ...formState, session: e.target.value })} />
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <FormControl fullWidth>
                <InputLabel
                  id="bill-form-status-label"
                  htmlFor="bill-form-status">Status</InputLabel>
                <Select
                  labelId="bill-status-label"
                  id="bill-form-status"
                  name="status"
                  value={formState.status}
                  label="Status."
                  onChange={handlePolicyCategoryChange}
                >
                  {Object.entries(statusText).map(([value, label]) => (
                    <MenuItem key={value} value={value}>{label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <h2>Classify Selected Bill</h2>

          <Grid container spacing={2}>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel
                  id="bill-form-policy-category-label"
                  htmlFor="bill-form-policy-category">Policy Category</InputLabel>
                <Select
                  labelId="bill-form-policy-category-label"
                  id="bill-form-policy-category"
                  name="policyCategory"
                  value={formState.policyCategory ?? ''}
                  label="Policy Category"
                  onChange={handlePolicyCategoryChange}
                  required
                >
                  <MenuItem key={'NONE'} value={''}>No policy</MenuItem>
                  {Object.entries(policyText).map(([value, text]) =>
                    <MenuItem key={value} value={value}>{text}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                id="policySubcategory"
                multiple
                disableCloseOnSelect
                value={formState.policySubcategory}
                options={Object.values(SubcategoryEnum)}
                noOptionsText={'No bills available'}
                getOptionLabel={(option) => subcategoryText[option]}
                renderInput={(props) => <TextField {...props} label="Policy Subcategory" />}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox checked={selected} />
                    {subcategoryText[option]}
                  </li>
                )}
                renderTags={(value, getTagProps, ownerState) =>
                  value.map((option, index) => <Chip {...getTagProps({ index })} label={subcategoryText[option]} />)
                }
                onChange={(event, value, reason, details) => {
                  setFormState({ ...formState, policySubcategory: value });
                }}
              />
            </Grid>

            <Grid item xs={12}>
              {spotPolicySubcategoryLoading && <CircularProgress />}
              {spotPolicySubcategoryError && <Typography>Failed to load policies from SPOT.</Typography>}
              {spotPolicySubcategoryData?.policySubcategories && (
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  value={spotPolicySubcategoryData?.policySubcategories.filter((b) =>
                    formState.spotPolicies.includes(b.id),
                  )}
                  options={spotPolicySubcategoryData?.policySubcategories}
                  noOptionsText={'No policies available'}
                  renderInput={(props) => (
                    <TextField {...props} label={'SPOT policies'} placeholder="Search SPOT policies" />
                  )}
                  getOptionLabel={(option) => option.title}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox checked={selected} />
                      {option.title}
                    </li>
                  )}
                  renderTags={(value, getTagProps, ownerState) =>
                    value.map((option, index) => <Chip {...getTagProps({ index })} label={option.title} />)
                  }
                  onChange={(event, value, reason, details) => {
                    setFormState({ ...formState, spotPolicies: value.map((v) => v.id) });
                  }}
                />
              )}
            </Grid>

          </Grid>

          <div>
            <h2>Companion Bills</h2>
            <p>To attach a companion bill, search by Bill Number.</p>

            <Autocomplete
              multiple
              disableCloseOnSelect
              value={companionOptions.filter((b) => formState.companionBills.includes(b.billId))}
              options={companionOptions}
              noOptionsText={'No bills available'}
              getOptionLabel={(option) => getBillLabel(option)}
              renderInput={(props) => <TextField {...props} placeholder="Search by bill number" />}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} />
                  {getBillLabel(option)}
                </li>
              )}
              renderTags={(value, getTagProps, ownerState) =>
                value.map((option, index) => <Chip {...getTagProps({ index })} label={option.number} />)
              }
              onChange={(event, value, reason, details) => {
                setFormState({ ...formState, companionBills: value.map((v) => v.billId) });
              }}
            />
          </div>

          <Box sx={{ my: '2rem', display: 'flex', gap: '1rem', justifyContent: 'center' }}>
            <ThemeProvider theme={buttonTheme}>
              <Button variant={"contained"} color={"primary"} type={'submit'}>Save</Button>
              <Button variant={"outlined"} color={"secondary"} type={'button'} onClick={onCancel}>Cancel</Button>
            </ThemeProvider>
          </Box>
        </form>
    </>
  );
};
